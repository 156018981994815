<template>
  <div>
    <el-container style="height: 100vh; border: 1px solid #eee">
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <div class="tit">AI管理后台</div>
        <!-- '0','1', '2', '3', '4', '5', '6', '7', '8' -->
        <el-menu :default-openeds="defaultOpen" :default-active="activeIndex">
          <template 
            v-for="(item, index) in menuList"
            >
            <template v-if="item.children && item.children.length>0">
              <el-submenu
                :key="index"
                :index="item.index"
                v-if="authorityFlag(item.authority)"
              >
                <template #title>
                  <!-- <i class="el-icon-message"></i> -->
                  {{ item.title }}
                </template>
                <el-menu-item-group>
                  <template 
                    v-for="(items, indexs) in item.children"
                    >
                    <el-menu-item
                      :key="indexs"
                      :index="items.index"
                      @click="linkFn(items)"
                      v-if="authorityFlag(items.authority)"
                      >{{ items.text }}</el-menu-item
                    >

                  </template>
                </el-menu-item-group>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item :key="index" :index="item.index" @click="linkFn(item)">{{item.title}}</el-menu-item>
            </template>
          </template>
        </el-menu>
      </el-aside>

      <el-container>
        <el-header style="text-align: right; font-size: 12px">
          <span>{{nickname}}</span>
          <span class="logout" @click="logout">退出</span>
        </el-header>

        <el-main>
          <router-view></router-view>
          <!-- <el-table :data="tableData">
            <el-table-column prop="date" label="日期" width="140">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column>
          </el-table> -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import store from '@/store'
export default {
  name: "Homepage",
  data() {
    return {
      nickname: '',
      activeIndex: "0",
      defaultOpen: [],
      menuList: [
        {
          index: "0",
          title: "首页",
          path: "/home",
        },
        {
          index: "1",
          title: "数据概况",
          path: "/dataProfiling",
        },
        // {
        //   index: "2",
        //   title: "权限配置",
        //   path: "/home",
        // },
        {
          index: "3",
          title: "AI角色配置",
          path: "/roleConfig",
        },
        {
          index: "4",
          title: "运营配置",
          path: "/operationalConfig",
        },
        {
          index: "5",
          title: "用户管理",
          path: "/userManage",
        },
        {
          index: "6",
          title: "推广配置",
          children: [
            {
              text: "渠道列表",
              index: "6-1",
              path: "/promotion/channel"
            },
            {
              text: "推广角色",
              index: "6-2",
              path: "/promotion/role"
            },
            {
              text: "推广概览",
              index: "6-3",
              path: "/promotion/overview"
            },
            {
              text: "渠道角色管理",
              index: "6-4",
              path: "/promotion/channelRole"
            },
            
          ],
        },
        {
          index: "7",
          title: "AI助手配置",
          path: "/aiAssistant",
        },
        {
          index: "8",
          title: "添加会员",
          path: "/addMember",
        },
        // {
        //   index: "1",
        //   title: "权限管理",
        //   authority: 'SYSTEM',
        //   children: [
        //     {
        //       text: "权限管理",
        //       index: "2-1",
        //       path: "/system/authority",
        //       authority: 'SYSTEM_AUTHORITY'
        //     },
        //     {
        //       text: "角色管理",
        //       index: "2-2",
        //       path: "/system/role",
        //       authority: 'SYSTEM_ROLE'
        //     },
        //     {
        //       text: "用户管理",
        //       index: "2-3",
        //       path: "/setup/user",
        //       authority: 'SETUP_USER'
        //     },
            
        //   ],
        // }
      ],
    };
  },
  created(){
    if(localStorage.getItem('activeIndex')){
      let activeIndex = localStorage.getItem('activeIndex')
      this.activeIndex = activeIndex
      this.defaultOpen = [activeIndex.split('-')[0]] || []
    }
    this.nickname = localStorage.getItem('nickname')
  },
  // 2.注册
  components: {},
  methods: {
    authorityFlag(){
      // authority
      // let flag = false
      // store.getters.authoritieList.map(item=>{
      //   if(item.authority === authority || !authority){
      //     flag = true
      //   }
      // })
      // return flag
      return true
    },
    logout(){
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("activeIndex");
      this.$router.push('/login')
    },
    linkFn(items){
        if (this.$router.history.current.fullPath !== items.path){
          this.$router.push({path:items.path})
          this.activeIndex = items.index
          localStorage.setItem('activeIndex',items.index)
        }
    }
  },
};
</script>

<style scoped>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.logout{
  cursor: pointer;
  margin-left: 20px;
}
.el-aside {
  color: #333;
}
.tit {
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  text-align: center;
}
</style>